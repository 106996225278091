@import "~antd/dist/antd.less";

@font-face {
  font-family: "Fugue";
  src: url("./fonts/fugue/FugueMonoKB.woff") format("woff"),
    url("./fonts/fugue/FugueMonoKB.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Univers";
  src: url("./fonts/univers/UniversLTCYR-45Light.woff") format("woff"),
    url("./fonts/univers/UniversLTCYR-45Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Univers";
  src: url("./fonts/univers/UniversLTCYR-55Roman.woff") format("woff"),
    url("./fonts/univers/UniversLTCYR-55Roman.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.not-supported {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.not-supported div {
  margin: 32px;
  padding: 32px;
}

@primary-color: #EE9173;